import React, { useState, useEffect } from 'react'
import Web3 from 'web3'
const assert = require('assert')
const { bigInt } = require('snarkjs')
const crypto = require('crypto')
const circomlib = require('circomlib')
const merkleTree = require('fixed-merkle-tree')
const buildGroth16 = require('websnark/src/groth16')
const websnarkUtils = require('websnark/src/utils')
import { Buffer } from 'buffer'
const { toWei } = require('web3-utils')
import circuit from './assets/withdraw.json'
import './style.css'
import Tooltip from './Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
global.Buffer = Buffer

function App() {
  const [note, setNote] = useState('')
  const [message, setMessage] = useState('')
  const [account, setAccount] = useState('')
  const [networkPopup, setNetworkPopup] = useState(false)
  const [web3, setWeb3] = useState(null)
  const [infuraWeb3, setInfuraWeb3] = useState(null)
  const [infuraContract, setInfuraContract] = useState(null)
  const [contract, setContract] = useState(null)
  const [noteInput, setNoteInput] = useState('')
  const [recipientAddress, setRecipientAddress] = useState('')
  const [currentNetwork, setCurrentNetwork] = useState('')
  const [netId, setNetId] = useState('')
  const [tabStatus, settabStatus] = useState('deposit')
  const [amount, setAmount] = useState('0.1')
  const [isConnecting, setIsConnecting] = useState(false)
  const [noteError, setNoteError] = useState('')
  const [withdrawError, setWithdrawError] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const [showDepoPopup, setShowDepoPopup] = useState(false)
  const [popTitle, setPopTitle] = useState('')
  const [popNoteTitle, setPopNoteTitle] = useState('')
  const [popupMessage, setPopupMessage] = useState('')
  const [popupNoteMessage, setPopupNoteMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [gasPrice, setGasPrice] = useState('');
  const [networkFee, setNetworkFee] = useState('');
  const [relayerFee, setRelayerFee] = useState('');
  const [totalFee, setTotalFee] = useState('');
  const [tokensToReceive, setTokensToReceive] = useState('');
  const [relayerUse, setRelayerUse] = useState(true);
  let ALCHEMY_URL;
  const networks = [
    { id: '0x1', name: 'Ethereum Mainnet', icon: '/assets/ether.png' },
    { id: '0x38', name: 'Binance Smart Chain', icon: '/assets/bnb.png' },
  ]

  const ALCHEMY_KEY = `8WiD-4V_FVNNCg0SXqDUgory-SSYPj9R`
  const DONATE_ADDRESS = '0x9B1E52bCA7373d918125d52a3035F3B6Ee635308'
  const FEE_ADDRESS = '0xEBF71611C33C2d7EC75bff545DE30ee9057f9A9F'
  const CONTRACT_ADDRESSES = {
    '0x1': { // Ethereum Mainnet
      '0.1': '0x50689DA146974dded2653b7438E3A0B411D1db15',
      '1': '0x742b661e3E9FfEbEB4a30C815AbeEa00fF647A4e',
      '10': '0x26a43FEFd634C5d181456421C2Ce6dF84be72bF1',
      '100': '0xD1a52835a776C147cf428376B03FA4240390E7d8',
    },
    '0x38': { // Binance Smart Chain
      '0.1': '0xDD877A109f6eE5F3D85f5B4b183e56ecaF3E9E6B',
      '1': '0x5c62de869b054d044AB77D210941CA162fD7F941',
      '10': '0xFc92D13bBCcd539dF9411e07AfDf94EB71d79759',
      '100': '0x405F4cB50a8c93571157B5e465095B6c8172FB49',
    },
  };
  const MERKLE_TREE_HEIGHT = 20

  const ABI = require('./assets/ETHSpinCash.json').abi

  useEffect(() => {
    const initializeConnection = async () => {
      try {
        if (!window.ethereum) {
          console.error('MetaMask is not installed!');
          return;
        }
  
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
  
        const accounts = await web3Instance.eth.getAccounts();
        if (accounts.length > 0) {
          console.log('MetaMask already connected.');
          const selectedAccount = accounts[0];
          const chainId = await web3Instance.eth.getChainId();
          const networkIdHex = `0x${chainId.toString(16)}`;
  
          const contractAddress = getContractAddress(networkIdHex, amount);
          if (!contractAddress) {
            console.error('No contract address found for the selected network and amount.');
            return;
          }
  
          // 스마트 컨트랙트와 Infura 설정
          const contractInstance = new web3Instance.eth.Contract(ABI, contractAddress);

          if(networkIdHex == '0x1') {
            ALCHEMY_URL = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`
          } else {
            ALCHEMY_URL = `https://bnb-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`
          }
          const infuraInstance = new Web3(new Web3.providers.HttpProvider(ALCHEMY_URL));
          infuraInstance.eth.transactionConfirmationBlocks = 1;
        
          const infuraContractInstance = new infuraInstance.eth.Contract(ABI, contractAddress);
  
          // 상태 업데이트
          setAccount(selectedAccount);
          setNetId(chainId);
          setCurrentNetwork(networkIdHex);
          setContract(contractInstance);
          setInfuraWeb3(infuraInstance);
          setInfuraContract(infuraContractInstance);
        } else {
          console.log('No MetaMask account connected. Requesting connection...');
          connectWallet(); // MetaMask 연결 요청
        }
      } catch (error) {
        console.error('Error during wallet initialization:', error.message);
      }
    };
  
    initializeConnection();
  }, []);

  if (typeof window.ethereum !== 'undefined') {
    window.ethereum.on('chainChanged', (chainIdHex) => {
      const newNetId = parseInt(chainIdHex, 16) // 16진수 형식을 숫자로 변환
      console.log('Network changed to:', newNetId)
      setNetId(newNetId) // 상태 업데이트
      setCurrentNetwork(chainIdHex)
  
      const contractAddress = getContractAddress(chainIdHex, amount)
  
      const contractInstance = new web3.eth.Contract(ABI, contractAddress)
      setContract(contractInstance)
  
      if(chainIdHex == '0x1') {
        ALCHEMY_URL = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`
      } else {
        ALCHEMY_URL = `https://bnb-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`
      }
  
      const infuraInstance = new Web3(new Web3.providers.HttpProvider(ALCHEMY_URL));
      infuraInstance.eth.transactionConfirmationBlocks = 1;
      setInfuraWeb3(infuraInstance);
    
      const infuraContractInstance = new infuraInstance.eth.Contract(ABI, contractAddress);
      setInfuraContract(infuraContractInstance);
    })
  
    // 계정 변경 감지
    window.ethereum.on('accountsChanged', (accounts) => {
      if (accounts.length > 0) {
        setAccount(accounts[0])
      } else {
        setAccount('')
      }
    })
  }
  
  const getContractAddress = (networkId, amount) => {
    const networkContracts = CONTRACT_ADDRESSES[networkId];
    return networkContracts ? networkContracts[amount] : null;
  };

  const connectWallet = async () => {
    try {
      if (!window.ethereum) {
        location.href = 'https://metamask.app.link/dapp/spincash.io'
        throw new Error('MetaMask is not installed!');
      }
  
      setIsConnecting(true); // 로딩 상태 설정
  
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
  
      // MetaMask 계정 요청
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      if (!accounts || accounts.length === 0) {
        throw new Error('No accounts found. Please connect MetaMask.');
      }
  
      const selectedAccount = accounts[0];
      setAccount(selectedAccount);
  
      // 네트워크 ID 가져오기
      const chainId = await web3Instance.eth.getChainId();
      setNetId(chainId);
      const networkIdHex = `0x${chainId.toString(16)}`;
      setCurrentNetwork(networkIdHex);
  
      // 컨트랙트 주소와 인스턴스 설정
      const contractAddress = getContractAddress(networkIdHex, amount);
      if (!contractAddress) {
        throw new Error('No contract address found for the selected network and amount.');
      }
  
      const contractInstance = new web3Instance.eth.Contract(ABI, contractAddress);
      setContract(contractInstance);
  
      if(networkIdHex == '0x1') {
        ALCHEMY_URL = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`
      } else {
        ALCHEMY_URL = `https://bnb-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`
      }
  
      const infuraInstance = new Web3(new Web3.providers.HttpProvider(ALCHEMY_URL));
      infuraInstance.eth.transactionConfirmationBlocks = 1;
      setInfuraWeb3(infuraInstance);
    
      const infuraContractInstance = new infuraInstance.eth.Contract(ABI, contractAddress);
      setInfuraContract(infuraContractInstance);
  
      console.log('Wallet connected successfully!');
    } catch (error) {
      console.error('Error connecting wallet:', error.message);
    } finally {
      setIsConnecting(false); // 로딩 상태 해제
    }
  };

  const copyNote = () => {
    if (!popupNoteMessage) {
      console.error("No note to copy");
      return;
    }
  
    // 최신 클립보드 API 사용
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(popupNoteMessage)
        .then(() => {
          console.log("Note copied to clipboard:", popupNoteMessage);
          alert("Note copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy note using Clipboard API:", err);
          fallbackCopyText(popupNoteMessage);
        });
    } else {
      // Fallback for unsupported browsers
      fallbackCopyText(popupNoteMessage);
    }
  };
  
  // Fallback 방법: 임시 textarea를 사용하여 복사
  const fallbackCopyText = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed"; // iOS에서 화면 스크롤 방지
    textarea.style.opacity = "0"; // 숨김 처리
    document.body.appendChild(textarea);
  
    textarea.select(); // 텍스트 선택
    try {
      document.execCommand("copy"); // 복사
      console.log("Note copied to clipboard using fallback:", text);
      alert("Note copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy note using fallback:", err);
    } finally {
      document.body.removeChild(textarea); // 임시 textarea 제거
    }
  };

  useEffect(() => {
    if (window.ethereum) {   
      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          console.log('MetaMask account changed:', accounts[0]);
        } else {
          console.error('MetaMask account disconnected.');
          setAccount(null);
        }
      };
  
      const handleChainChanged = (chainId) => {
        const networkIdHex = `0x${parseInt(chainId, 16).toString(16)}`;
        setNetId(parseInt(chainId, 16));
        setCurrentNetwork(networkIdHex);
        console.log('Network changed:', networkIdHex);
        connectWallet(); // 네트워크 변경 시 상태 재설정
      };
  
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);
  
      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      };
    }
  }, []);  

  useEffect(() => {
    // noteInput과 recipientAddress가 모두 있을 때만 실행
    if (noteInput && recipientAddress) {
      const fetchFees = async () => {
        await calculateFees();
      };
  
      fetchFees();
    }
  }, [noteInput, recipientAddress]); // 종속성 배열

  useEffect(() => {
    const handleChainChanged = (chainId) => {
      console.log('Network changed to:', chainId);
      setNetId(parseInt(chainId, 16)); // 네트워크 ID 업데이트
      setCurrentNetwork(chainId); // 현재 네트워크 업데이트
    };
  
    if (window.ethereum) {
      // 기존 리스너 제거
      window.ethereum.removeListener('chainChanged', handleChainChanged);
  
      // 새로운 리스너 추가
      window.ethereum.on('chainChanged', handleChainChanged);
    }
  
    return () => {
      // 컴포넌트 언마운트 시 리스너 제거
      if (window.ethereum) {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);
  
  const calculateFees = async () => {
    if (web3 && contract) {
      try {
        // 현재 가스 가격 가져오기
        const gasPriceInWei = await web3.eth.getGasPrice(); // Wei 단위
        const gasPriceInGwei = web3.utils.fromWei(gasPriceInWei, 'gwei'); // Gwei 단위로 변환
        setGasPrice(`${gasPriceInGwei} Gwei`);
  
        // 가스 한도 설정
        const gasLimit = 1e6; // 예상 가스 한도
        const networkFeeInWei = BigInt(gasPriceInWei) * BigInt(gasLimit);
        const networkFeeInBNB = parseFloat(web3.utils.fromWei(networkFeeInWei.toString(), 'ether')); // BNB 단위
        setNetworkFee(`${networkFeeInBNB.toFixed(6)} ${currentNetwork ? currentNetwork === '0x1' ? 'ETH' : currentNetwork === '0x38' ? 'BNB' : 'ETH' : 'ETH'}`);
  
        // 리레이어 수수료 계산
        const relayerFeeInBNB = parseFloat(amount) * 0.075 / 100; // 리레이어 수수료 0.075%
        setRelayerFee(`${relayerFeeInBNB.toFixed(6)} ${currentNetwork ? currentNetwork === '0x1' ? 'ETH' : currentNetwork === '0x38' ? 'BNB' : 'ETH' : 'ETH'}`);
  
        // 총 수수료 계산
        const totalFeeInBNB = networkFeeInBNB + relayerFeeInBNB;
        setTotalFee(`${totalFeeInBNB.toFixed(6)} ${currentNetwork ? currentNetwork === '0x1' ? 'ETH' : currentNetwork === '0x38' ? 'BNB' : 'ETH' : 'ETH'}`);
  
        // 받을 토큰 계산
        const tokensToReceiveInBNB = parseFloat(amount) - totalFeeInBNB;
        setTokensToReceive(`${tokensToReceiveInBNB.toFixed(6)} ${currentNetwork ? currentNetwork === '0x1' ? 'ETH' : currentNetwork === '0x38' ? 'BNB' : 'ETH' : 'ETH'}`);
      } catch (error) {
        console.error('Error calculating fees:', error);
      }
    }
  };

  const switchNetwork = async (network) => {
    try {
      // Check if MetaMask is installed
      if (!window.ethereum) {
        alert('MetaMask is not installed! Please install MetaMask and try again.');
        return
      }
  
      // Check if MetaMask is connected
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      if (!accounts || accounts.length === 0) {
        alert('MetaMask is not connected! Requesting connection...');
        return
      }
  
      // Switch network
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network.id }],
      });
      setCurrentNetwork(network.id);
      setNetworkPopup(false); // Close popup
      location.reload(); // Reload to reflect network changes
    } catch (error) {
      console.error('Error switching network:', error.message);
  
      // If the user cancels the network switch, you may show an alert
      if (error.code === 4001) {
        alert('Network switch was cancelled. Please try again.');
      } else {
        alert('An error occurred while switching network. Please try again.');
      }
    }
  };

  const getNetworkName = (networkId) => {
    if (networkId === '0x1') return 'Ethereum'
    if (networkId === '0x38') return 'BSC Mainnet'
    return 'Ethereum'
  }

  const tabStatusChange = (status) => {
    if (status !== tabStatus) {
      settabStatus(status);
    }
  };

  const handleAmountChange = (value) => {
    setAmount(value);

    const contractAddress = getContractAddress(currentNetwork, value)
    const contractInstance = new web3.eth.Contract(ABI, contractAddress)
    setContract(contractInstance)

    const infuraInstance = new Web3(new Web3.providers.HttpProvider(ALCHEMY_URL));
    infuraInstance.eth.transactionConfirmationBlocks = 1;
    setInfuraWeb3(infuraInstance);

    const infuraContractInstance = new infuraInstance.eth.Contract(ABI, contractAddress)
    setInfuraContract(infuraContractInstance)
  };

  const isValidNote = (note) => {
    const noteRegex = currentNetwork === '0x1'
    ? /^SPIN-ETH-\d+(\.\d+)?-\d+-0x[0-9a-fA-F]{124}$/
    : /^SPIN-BNB-\d+(\.\d+)?-\d+-0x[0-9a-fA-F]{124}$/
    
    if(noteRegex.test(note)) {
      setWithdrawError('')
      checkNote(note)
    }
    return noteRegex.test(note)
  }

  const checkNote = async (note) => {
    const deposit = parseNote(note)
    const { root, pathElements, pathIndices } = await generateMerkleProof(deposit)
  }

  const inputDonate = () => {
    setRecipientAddress(DONATE_ADDRESS);
  }

  function Popup({ message, onClose }) {
    return (
      <div className="popup-overlay">
        <div className="popup-content">
          <h3>{popTitle}</h3>
          <p>{message}</p>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  function DepoPopup({ message, onClose }) {
    return (
      <div className="popup-overlay">
        <div className="popup-content">
          <h3>{popNoteTitle}</h3>
          <a onClick={copyNote}><p>{popupNoteMessage} <FontAwesomeIcon icon={faClone} /></p></a>
          <h3>{popTitle}</h3>
          <p>{message}</p>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  function LoadingOverlay({ message }) {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
        <p>{message}</p>
      </div>
    );
  }

  function saveTextAsFile(text, fileName) {
    const blob = new Blob([text], { type: 'text/plain' });
    
    // 모바일에서도 파일 다운로드를 지원하기 위한 a 태그 생성
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
  
    // 모바일 브라우저 호환성 처리
    if (navigator.userAgent.match(/Android|iPhone|iPad|iPod/i)) {
      // 모바일의 경우 a 태그를 DOM에 추가 후 클릭 이벤트 트리거
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 사용 후 DOM에서 제거
    } else {
      // 데스크톱 환경
      link.click();
    }
  
    // URL 메모리 해제
    URL.revokeObjectURL(link.href);
  }

  /** Generate random number of specified byte length */
  const rbigint = (nbytes) => bigInt.leBuff2int(crypto.randomBytes(nbytes))

  /** Compute pedersen hash */
  const pedersenHash = (data) => circomlib.babyJub.unpackPoint(circomlib.pedersenHash.hash(data))[0]

  /** BigNumber to hex string of specified length */
  const toHex = (number, length = 32) =>
    '0x' +
    (number instanceof Buffer ? number.toString('hex') : bigInt(number).toString(16)).padStart(
      length * 2,
      '0',
    )

  /** Create deposit object from secret and nullifier */
  const createDeposit = (nullifier, secret) => {
    let deposit = { nullifier, secret }
    deposit.preimage = Buffer.concat([deposit.nullifier.leInt2Buff(31), deposit.secret.leInt2Buff(31)])
    deposit.commitment = pedersenHash(deposit.preimage)
    deposit.nullifierHash = pedersenHash(deposit.nullifier.leInt2Buff(31))
    return deposit
  }
  
  async function deposit() {
    try {
      const balance = await web3.eth.getBalance(account);
      const balanceInBNB = web3.utils.fromWei(balance, 'ether');
      setLoadingMessage('Deposit...');
      setIsLoading(true);
  
      if (parseFloat(balanceInBNB) < parseFloat(amount)) {
        setPopTitle(`Insufficient balance`);
        setPopupMessage(`You do not have enough ${currentNetwork ? (currentNetwork === '0x1' ? 'ETH' : currentNetwork === '0x38' ? 'BNB' : 'ETH') : 'ETH'} tokens. Your current balance is ${balanceInBNB} ${currentNetwork ? (currentNetwork === '0x1' ? 'ETH' : currentNetwork === '0x38' ? 'BNB' : 'ETH') : 'ETH'}`);
        setIsLoading(false);
        setShowPopup(true);
        return;
      }
  
      const deposit = createDeposit(rbigint(31), rbigint(31));
      console.log('Sending deposit transaction...');
      const note = `SPIN-${currentNetwork ? (currentNetwork === '0x1' ? 'ETH' : currentNetwork === '0x38' ? 'BNB' : 'ETH') : 'ETH'}-${amount}-${netId}-${toHex(deposit.preimage, 62)}`;
  
      const tx = await contract.methods
        .deposit(toHex(deposit.commitment))
        .send({ value: toWei(amount), from: account, gas: 2e6 });
  
      saveTextAsFile(note, `deposit-note-${Date.now()}.txt`);
      setPopNoteTitle(`Deposit Note`);
      setPopupNoteMessage(note);
      setPopTitle(`Deposit Success`);
      setPopupMessage(`Transaction successful! View on ${currentNetwork === '0x1' ? 'Etherscan' : 'BscScan'}: ${currentNetwork === '0x1' ? `https://etherscan.io/tx/${tx.transactionHash}` : `https://bscscan.com/tx/${tx.transactionHash}`}`);
      setShowDepoPopup(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Error during deposit transaction:', error);

      const errorMessage = String(error);

      if (errorMessage.includes('MessageNode outgoing id not exist!')) {
        setPopTitle('Transaction Error');
        setPopupMessage('An unexpected issue occurred with the transaction. Please try again or contact support.');
        setShowPopup(true);
      } else if (error.code === 4001) { // MetaMask user rejected transaction
        setPopTitle('Transaction Rejected');
        setPopupMessage('You have rejected the transaction in MetaMask.');
        setShowPopup(true);
      } else {
        setPopTitle('Transaction Failed');
        setPopupMessage('An error occurred while processing the transaction. Please try again.');
        setShowPopup(true);
      }  
    } finally {
      setIsLoading(false);
    }
  }  

  function parseNote(noteString) {
    const noteRegex = /SPIN-(?<currency>\w+)-(?<amount>[\d.]+)-(?<netId>\d+)-0x(?<note>[0-9a-fA-F]{124})/g
    const match = noteRegex.exec(noteString)


    const buf = Buffer.from(match.groups.note, 'hex')
    const nullifier = bigInt.leBuff2int(buf.slice(0, 31))
    const secret = bigInt.leBuff2int(buf.slice(31, 62))
    return createDeposit(nullifier, secret)
  }

  async function fetchAllDepositEvents(contract, startBlock, endBlock, step = 10000000) {
    const events = []
    for (let fromBlock = startBlock; fromBlock <= endBlock; fromBlock += step) {
      const toBlock = Math.min(fromBlock + step - 1, endBlock)
      const partialEvents = await contract.getPastEvents('Deposit', { fromBlock, toBlock })
      events.push(...partialEvents)
    }
    return events
  }

  async function generateMerkleProof(deposit) {
    const latestBlock = await infuraWeb3.eth.getBlockNumber()
    setIsLoading(true);
    setLoadingMessage('Getting the note data...');
    console.log('Getting contract state...')
    const events = await fetchAllDepositEvents(infuraContract, 0, latestBlock)
    const leaves = events
      .sort((a, b) => a.returnValues.leafIndex - b.returnValues.leafIndex) // Sort events in chronological order
      .map((e) => e.returnValues.commitment)
    const tree = new merkleTree(MERKLE_TREE_HEIGHT, leaves)

    // Find current commitment in the tree
    let depositEvent = events.find((e) => e.returnValues.commitment === toHex(deposit.commitment))
    let leafIndex = depositEvent ? depositEvent.returnValues.leafIndex : -1
    console.log('commitment:', toHex(deposit.commitment))
    console.log('Nullifier Hash:', toHex(deposit.nullifierHash))
    // Validate that our data is correct (optional)
    const isValidRoot = await contract.methods.isKnownRoot(toHex(tree.root())).call()
    if (!isValidRoot) {
      console.error('Local Merkle root does not match the contract root.')
      console.log('Local Root:', toHex(tree.root()))
    }
    const isSpent = await contract.methods.isSpent(toHex(deposit.nullifierHash)).call()

    if (isSpent === true) {
      setNoteError('Note has been spent')
      setRelayerUse(false)
      setIsLoading(false)
      return
    } else if(leafIndex < 0) {
      setWithdrawError('There is no related deposit. The note is invalid.')
      setRelayerUse(false)
      setIsLoading(false)
      return
    }
    setRelayerUse(true)
    setIsLoading(false)
    
    assert(isValidRoot === true, 'Merkle tree is corrupted')
    assert(isSpent === false, 'The note is already spent')
    assert(leafIndex >= 0, 'The deposit is not found in the tree')

    // Compute merkle proof of our commitment
    const { pathElements, pathIndices } = tree.path(leafIndex)
    return { pathElements, pathIndices, root: tree.root() }
  }

  async function loadProvingKey() {
    const response = await fetch('/assets/withdraw_proving_key.bin')
    if (!response.ok) {
      throw new Error(`Failed to load proving key: ${response.statusText}`)
    }
    const buffer = await response.arrayBuffer()

    return buffer // ArrayBuffer 반환
  }

  async function generateSnarkProof(deposit, recipient) {
    // Compute merkle proof of our commitment
    const { root, pathElements, pathIndices } = await generateMerkleProof(deposit)
    setIsLoading(true);
    // Prepare circuit input
    const input = {
      // Public snark inputs
      root: root,
      nullifierHash: deposit.nullifierHash,
      recipient: bigInt(recipient),
      relayer: bigInt(FEE_ADDRESS),
      fee: toWei((0.075*amount/100).toString()),
      refund: 0,

      // Private snark inputs
      nullifier: deposit.nullifier,
      secret: deposit.secret,
      pathElements: pathElements,
      pathIndices: pathIndices,
    }

    const provingKey = await loadProvingKey()
    const groth16 = await buildGroth16()
    const proofData = await websnarkUtils.genWitnessAndProve(groth16, input, circuit, provingKey)
    const { proof } = websnarkUtils.toSolidityInput(proofData)

    const args = [
      toHex(input.root),
      toHex(input.nullifierHash),
      toHex(input.recipient, 20),
      toHex(input.relayer, 20),
      toHex(input.fee),
      toHex(input.refund),
    ]

    return { proof, args }
  }

  async function withdraw(noteInput, recipientAddress) {
    const deposit = parseNote(noteInput)
    const { proof, args } = await generateSnarkProof(deposit, recipientAddress)
    console.log('Sending withdrawal transaction...')
    try{
      const tx = await contract.methods.withdraw(proof, ...args).send({ from: account, gas: 1e6 })
      setPopTitle(`Withdraw Success`)
      {currentNetwork ? currentNetwork === '0x1' ? setPopupMessage(`https://etherscan.io//tx/${tx.transactionHash}`) : currentNetwork === '0x38' ? setPopupMessage(`https://bscscan.com//tx/${tx.transactionHash}`) : setPopupMessage(`https://etherscan.io//tx/${tx.transactionHash}`) : setPopupMessage(`https://etherscan.io//tx/${tx.transactionHash}`)}
      setShowPopup(true);
      setIsLoading(false)
      setNoteInput('')
      setRecipientAddress('')
    } catch (error) {
      console.error('Error fetching contract state:', error);
      setIsLoading(false)
      setNoteInput('')
      setRecipientAddress('')
    }
  }

  return (
    <div className="container">
      <div className="hederWrap">
        <div>
          <a href="/">
            <img src="/assets/logo.png" />
          </a>
        </div>
        <div>
          <button onClick={() => setNetworkPopup(!networkPopup)} className="networkButton">
            {currentNetwork && (
              <img
                src={
                  currentNetwork === '0x1'
                    ? '/assets/ether.png'
                    : currentNetwork === '0x38'
                    ? '/assets/bnb.png'
                    : '/assets/ether.png'
                }
                alt="Network Logo"
                style={{ width: '20px', height: '20px', marginRight: '8px' }}
              />
            )}
            {currentNetwork ? `${getNetworkName(currentNetwork)}` : 'Ethereum'}
          </button>
          <button onClick={connectWallet} className={account ? 'connected' : 'disconnected'}>
            <i />
          </button>
        </div>
      </div>
      {networkPopup && (
        <div className="popup">
          <div className="popupContent">
            <h3>Change Network</h3>
            <ul>
              {networks.map((network) => (
                <li
                  key={network.id}
                  onClick={() => switchNetwork(network)}
                  className={currentNetwork === network.id ? 'selected' : ''}
                >
                  <img src={network.icon} /> {network.name}
                  {currentNetwork === network.id && <span className="check">✔</span>}
                </li>
              ))}
            </ul>
            <button className="closeButton" onClick={() => setNetworkPopup(false)}>
              Close
            </button>
          </div>
        </div>
      )}

      <div className="contWrap">
        <div className="tabWrap">
          <ul>
            <li className={tabStatus === 'deposit' ? 'button_tab_deposit selected' : 'button_tab_deposit '}>
              <a onClick={() => tabStatusChange('deposit')}>
                <span>Deposit</span>
              </a>
            </li>
            <li
              className={tabStatus === 'withdraw' ? 'button_tab_withdraw selected' : 'button_tab_withdraw '}
            >
              <a onClick={() => tabStatusChange('withdraw')}>
                <span>Withdraw</span>
              </a>
            </li>
          </ul>
          {tabStatus === 'deposit' && (
            <div className='tab-content'>
              <div className='tokenSel'>
                <label>Token</label>
                <select>
                  <option
                    value={ currentNetwork ? currentNetwork === '0x1' ? 'ETH' : currentNetwork === '0x38' ? 'BNB' : 'ETH' : 'ETH' } >
                    {currentNetwork ? currentNetwork === '0x1' ? 'ETH' : currentNetwork === '0x38' ? 'BNB' : 'ETH' : 'ETH'}
                  </option>
                </select>
              </div>
              <div className='amountSel'>
                <h3>Amount</h3>
                <div>
                  <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {['0.1', '1', '10', '100'].map((value) => (
                      <li key={value} onClick={() => handleAmountChange(value)} >
                        <a className={`step-link ${amount === value ? 'active' : ''}`}>
                          <div className="step-marker"></div>
                          <div className="step-details"><span className="step-title">{value} {currentNetwork ? currentNetwork === '0x1' ? 'ETH' : currentNetwork === '0x38' ? 'BNB' : 'ETH' : 'ETH'}</span></div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {account && contract ? 
                <button className='depositBtn' onClick={deposit} disabled={!account}>
                  Deposit
                </button>
                : 
                <button className='depositBtn' onClick={connectWallet}>
                  Connect
                </button>
              }
              
            </div>
          )}
          {tabStatus === 'withdraw' && (
            <div className='tab-content'>
              <div className='noteWrap'>
                <div>
                <h3>Note</h3>
                  <Tooltip text="Please enter the note you received after deposit was made">
                    <span className="info-icon">i</span>
                  </Tooltip>
                </div>
                <input
                  type="text"
                  placeholder="Please enter your note"
                  value={noteInput}
                  onChange={(e) => {
                    const value = e.target.value
                    setNoteInput(value)
                    setNoteError(value.trim() === '' ? '' : isValidNote(value) ? '' : 'Note is invalid')
                  }}
                  disabled={!account}
                />
                {noteError && <p className='noteError'>{noteError}</p>}
              </div>
              <div className='recipientWrap'>
                <div>
                  <h3>Recipient Address</h3>
                  <a onClick={inputDonate}>Donate</a>
                </div>
                <input
                  className='recipientInput'
                  type="text"
                  placeholder="Please paste address here"
                  value={recipientAddress}
                  onChange={(e) => setRecipientAddress(e.target.value)}
                  disabled={!account}
                />
                {withdrawError && <p className='withdrawError'><span>Error</span> {setWithdrawError === '' ? '' : withdrawError}</p>}
              </div>
              {noteInput && recipientAddress && relayerUse && (
                <div className="feeSummary">
                  <h3>Current Relayer</h3>
                  <div><p>Name:</p><p>default-relayer</p></div>
                  <div><p>Fee:</p><p>0.075%</p></div>

                  <h3>Total</h3>
                  <div><p>Gas Price:</p><p>{gasPrice}</p></div>
                  <div><p>Network Fee:</p><p>{networkFee}</p></div>
                  <div><p>Relayer Fee:</p><p>{relayerFee}</p></div>
                  <div><p>Total Fee:</p><p>{totalFee}</p></div>
                  <div><p>Tokens to Receive:</p><p>{tokensToReceive}</p></div>
                </div>
              )}
              <button className='withdrawBtn' onClick={() => withdraw(noteInput, recipientAddress)} disabled={!account || !noteInput.trim() || !recipientAddress.trim()}>
                Withdraw
              </button>
            </div>
          )}
        </div>
        <div className="Statistics"></div>
      </div>
      {showDepoPopup && (
        <DepoPopup
          notetitle={popNoteTitle}
          notemessage={popupNoteMessage}
          title={popTitle}
          message={popupMessage}
          onClose={() => setShowDepoPopup(false)}
        />
      )}
      {showPopup && (
        <Popup
          title={popTitle}
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
      {isLoading && <LoadingOverlay message={loadingMessage} />}
    </div>
  )
}

export default App

import React from 'react';
import ReactDOM from 'react-dom/client'; // ReactDOM 대신 ReactDOM.client 가져오기
import App from './App';
import { Buffer } from 'buffer'
import process from 'process'

global.Buffer = Buffer
global.process = process

const root = ReactDOM.createRoot(document.getElementById('root')); // createRoot 사용
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
